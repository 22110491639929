import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { Helmet } from "react-helmet";

export default function About() {
  const Site_Url = "https://namechangeinaadhaarcard.com/";
  const BASE_URL = Site_Url + "admin/API/";

  const [slider, setbannerdata] = useState([]);
  useEffect(() => {
    const AllBanner = async () => {
      const response = await fetch(`${BASE_URL}bannerAPI.php`);
      const jsonData = await response.json();
      setbannerdata(jsonData);
    };
    AllBanner();
  }, [BASE_URL]);

  // WebSite Setting
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //Page Meta Tags
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    const MetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}pagemetaTags.php`);
      const jsonData = await response.json();
      setPagemetaTags(jsonData);
    };
    MetaTagsData();
  }, [BASE_URL]);

  //Testimonial API
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    const Allclient = async () => {
      const response = await fetch(`${BASE_URL}clientAPI.php`);
      const jsonData = await response.json();
      setclientdata(jsonData);
    };
    Allclient();
  }, [BASE_URL]);

  const options1 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    loop: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      800: {
        items: 3,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>{PagemetaTags.AboutMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.AboutMetaKeyword} />
        <meta name="description" content={PagemetaTags.AboutMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {/* Banner Start */}
      <section>
        <div className="banner">
          <img
            src={slider.about_banner}
            alt={slider.about_banner_alt}
            title={slider.about_banner_title}
          />
        </div>
      </section>
      {/* Banner End */}

      <section className="about_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="about_tx">
                <h4>About Us</h4>
                <h2>{webSetting.about_title}</h2>
                <p>{webSetting.about_tagline}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: webSetting.about_desc,
                  }}
                ></p>
                {/* <a href="/">Read More</a> */}
              </div>
            </div>

            <div className="col-md-6">
              <div className="about_img">
                <img
                  src={webSetting.about_img}
                  alt="About"
                  title="About Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Start */}
      <section className="testimonial_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about_tx text-center">
                <h4>Testimonials </h4>
                <h2> We love our clients</h2>
              </div>

              <OwlCarousel className="owl-theme" {...options1}>
                {clientdata.map((value,index) => 
                      <div key={index} className="item client_review">
                        <img src={value.image} alt="" title="" />
                        <h5>{value.name}</h5>
                        <p className="text-center">{value.designation}</p>
                        <p>{value.description}</p>
                      </div>
                    )}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials Start */}
    </>
  );
}
