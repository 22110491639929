import React from 'react';
import { BrowserRouter as Router , Routes , Route, Navigate } from 'react-router-dom';
import Header from './component/Header.js';
import Home from './component/Home.js';
import End from './component/End.js';
import About from './component/About.js';
import Blog from './component/Blog.js';
import Service from './component/Service.js';
import Contact from './component/Contact.js';
import BlogDetails from './component/BlogDetails.js';
import ScrollToTop from './component/ScrollToTop.js';


function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/blog/:language" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route exact="true" path="/:lang/:slug" element={<BlogDetails />} />
          <Route exact="true" path="/:slug" element={<BlogDetails />} />
          <Route exact="true" path="/*" element={<Navigate to="/" />} />
        </Routes>
        <End />
      </Router>
    </>
  );  
}

export default App;
